<template>
  <div class="user-profile-container" v-if="dataLoaded && user !== null">
    <a-row style="marginTop: 3.5%">
      <a-col :span="12" :offset="6">
        <a-card :title="$t('users.profile')">
          <a slot="extra" @click="edit()">{{ $t("form.edit") }}</a>
          <a-form>
            <a-row>
              <a-col>
                <a-form-item
                  :label="$t('users.firstName')"
                  :label-col="formItemLayout.labelCol"
                  labelAlign="left"
                  :validate-status="getValidateStatus('firstName')"
                >
                  <a-input
                    v-if="editingTarget"
                    :placeholder="$t('users.firstName')"
                    v-model="editingTarget.firstName"
                    @change="
                      (e) => handlePropChange('firstName', e.target.value)
                    "
                  />
                  <span v-else> {{ user.firstName }} </span>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-form-item
                :label="$t('users.lastName')"
                :label-col="formItemLayout.labelCol"
                labelAlign="left"
                :validate-status="getValidateStatus('lastName')"
              >
                <a-input
                  v-if="editingTarget"
                  :placeholder="$t('users.lastName')"
                  :value="editingTarget.lastName"
                  @change="(e) => handlePropChange('lastName', e.target.value)"
                />
                <span v-else> {{ user.lastName }} </span>
              </a-form-item>
            </a-row>
            <a-row>
              <a-form-item
                :label="$t('users.email')"
                :label-col="formItemLayout.labelCol"
                labelAlign="left"
                :validate-status="getValidateStatus('email')"
              >
                <a-input
                  v-if="editingTarget"
                  :disabled="editingTarget !== null"
                  :placeholder="$t('users.email')"
                  :value="editingTarget.email"
                  @change="(e) => handlePropChange('email', e.target.value)"
                />
                <span v-else> {{ user.email }} </span>
              </a-form-item>
            </a-row>
            <a-row>
              <a-form-item
                :label="$t('users.phoneNumber')"
                :label-col="formItemLayout.labelCol"
                labelAlign="left"
                :validate-status="getValidateStatus('phoneNumber')"
              >
                <a-input
                  v-if="editingTarget"
                  style="width: 100%"
                  :placeholder="$t('users.phoneNumber')"
                  :defaultValue="editingTarget.phoneNumber"
                  @change="
                    (e) => handlePropChange('phoneNumber', e.target.value)
                  "
                ></a-input>
                <span v-else> {{ user.phoneNumber }}</span>
              </a-form-item>
            </a-row>
            <a-row>
              <a-form-item
                :label="$t('users.roles')"
                :label-col="formItemLayout.labelCol"
                labelAlign="left"
              >
                <a-tag
                  style="fontSize: 14px"
                  v-for="role in user.roles"
                  :key="role.id"
                >
                  {{ role.name }}
                </a-tag>
              </a-form-item>
            </a-row>
            <a-row>
              <a-form-item
                label="Sites"
                :label-col="formItemLayout.labelCol"
                :wrapper-col="formItemLayout.wrapperCol"
                labelAlign="left"
              >
                <a-tag
                  style="fontSize: 14px"
                  v-for="site in userSites"
                  :key="site.id"
                >
                  {{ site.name }}
                </a-tag>
              </a-form-item>
            </a-row>
            <a-form-item v-if="editingTarget">
              <a-row>
                <a-col
                  :xs="{ span: 10, offset: 2 }"
                  :sm="{ span: 11, offset: 0 }"
                  :md="{ span: 9, offset: 2 }"
                  :xl="{ span: 5, offset: 8 }"
                  :xxl="{ span: 4, offset: 8 }"
                >
                  <a-button
                    @click="() => cancel()"
                    class="btn btn-danger"
                    type="danger"
                  >
                    {{ $t("form.cancel") }}
                  </a-button>
                </a-col>
                <a-col :xs="14" :sm="6" :md="8" :xl="8" :xxl="8">
                  <a-button @click="() => save()" class="btn" type="primary">
                    {{ $t("form.save") }}
                  </a-button>
                </a-col>
              </a-row>
            </a-form-item>
          </a-form>
        </a-card>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12" :offset="6">
      <a-card :title="$t('users.accountSecurity')" >
          <a-row style="marginRight: -10px">
            <a-row v-if="!editingPassword">
               <p style="fontSize: 14px;color: rgba(0, 0, 0, 0.85); marginBottom: 16px;fontWeight: 500">{{$t('users.password')}} :</p>
                <a-button  @click="() => EditPassword()" class="btn" type="primary">
                    {{ $t('form.resetPassword') }}
                  </a-button>
            </a-row>
            <a-form-model v-if="editingPassword"
              layout="vertical"
              ref="addUserForm"
              :model="user"
              @submit="save"
              @submit.native.prevent
            >
            <a-alert v-if="error" type="error" :message="error" banner />
            <a-row  style="marginTop: 1%">
              <a-col  >
                <a-form-model-item
                  :label="$t('users.newPassword')"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  hasFeedback
                  :validate-status="getValidateStatus('newPassword')"
                >
                  <a-input-password
                    :placeholder="$t('users.newPassword')"
                    v-model="form.newPassword"
                    style="width: 100%"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <a-form-model-item
                  :label="$t('users.confirmPassword')"
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  hasFeedback
                  :validate-status="getValidateStatus('confirmNewPassword')"
                >
                  <a-input-password
                    :placeholder="$t('users.confirmPassword')"
                    v-model="form.confirmNewPassword"
                    style="width: 100%"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
                {{ $t("users.informationSecurity") }}
            <a-form-model-item>
              <a-row style="marginTop: 3.5%">
                <a-col
                  :xs="{ span: 10, offset: 2 }"
                  :sm="{ span: 11, offset: 0 }"
                  :md="{ span: 9, offset: 2 }"
                  :xl="{ span: 5, offset: 8 }"
                  :xxl="{ span: 4, offset: 8 }"
                >
                  <a-button
                    @click="() => ClosePassword()"
                    class="btn btn-danger"
                    type="danger"
                  >
                    {{ $t("form.cancel") }}
                  </a-button>
                </a-col>
                <a-col :xs="14" :sm="6" :md="8" :xl="8" :xxl="8">
                  <a-button @click="() => save_password()" class="btn" type="primary">
                    {{ $t("form.save") }}
                  </a-button>
                </a-col>
              </a-row>
            </a-form-model-item>
          </a-form-model>
        </a-row>
      </a-card></a-col>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Row, Col, Card, Form, Input, Tag, Space } from "ant-design-vue";
import { userRoleGroup } from "../../utils/userAuth";
import { required, minLength, sameAs } from "vuelidate/lib/validators";

Vue.use(Row);
Vue.use(Col);
Vue.use(Card);
Vue.use(Form);
Vue.use(Input);
Vue.use(Tag);
Vue.use(Space);

const passwordRegEx = RegExp(
  "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$"
);

export default {
  name: "user-profile",
  computed: {
    userSites() {
      let userSites = [];
      this.user.sites.forEach((id) => {
        const site = this.sites.find((s) => s.id === id);
        if (site) {
          userSites.push({
            id: id,
            name: site.name,
          });
        }
      });
      return userSites;
    },
    user() {
      return this.$store.getters.getCurrentUser()
    }
  },
  mounted() {
    if (this.$store.state.users.lastCurrentUserFetch === null) {
      let current = this.$store.getters.getCurrentUserRoles();
      if (current) {
        this.$store.dispatch("setCurrentUser").then(() => {
          this.dataLoaded = true;
        });
      }
    } else {
      this.dataLoaded = true;
    }
    this.$store.dispatch("fetchSites").then(() => {
      this.sites = [...this.$store.state.sites.sites];
    });
  },
  data() {
    return {
      editingTarget: null,
      editingPassword: null,
      formItemLayout: {
        labelCol: { span: 6 },
      },
      invalidFields: [],
      dataLoaded: false,
      sites: [],
      form: {},
      submitted: false,
      error: null,
    };
  },
  validations: {
    form: {
      password: { required },
      newPassword: { required, minLength: minLength(6) },
      confirmNewPassword: { required, sameAsPassword: sameAs("newPassword") },
    },
  },
  methods: {
    EditPassword() {
      this.editingPassword = "edit";
    },
    ClosePassword() {
      this.editingPassword = null;
    },
    save_password() {
      this.submitted = true;
      this.invalidFields = [];
      let passwordMatch = passwordRegEx.test(this.form.newPassword);
      if (!passwordMatch) {
        this.error = this.$t('authentication.globalInvalid')
        this.invalidFields.push("newPassword");
      }
      if (this.invalidFields.length === 0) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          if (
            !this.$v.form.newPassword.minLength ||
            !this.$v.form.newPassword.required
          ) {
            this.error = this.$t('authentication.passwordInvalid')
            this.invalidFields.push("newPassword");
          }
          if (
            !this.$v.form.confirmNewPassword.sameAsPassword ||
            !this.$v.form.confirmNewPassword.required
          ) {
            this.error = this.$t('authentication.passwordnotIndentical')
            this.invalidFields.push("confirmNewPassword");
          }
          this.submitted = false;
        }
        if (this.invalidFields.length === 0) {
          this.error = "";
           var stock = this.$store.getters.getCurrentUser()
            stock["password"] = this.form.newPassword
            stock.role = userRoleGroup(stock.roles);
            delete stock.roles;
            this.$store.dispatch("updateUser", stock).then(() => {
              this.editingPassword = null;
            })
            this.$store.dispatch("updateToken")
        }
      }
    },
    handlePropChange(field, value) {
      this.editingTarget[field] = value;
    },
    edit() {
      let target = { ...this.user };
      delete target.roles;
      target.role = userRoleGroup(this.user.roles);
      this.editingTarget = {...target};
    },
    save() {
      if (Object.hasOwnProperty.call(this.editingTarget, "id")) {
        if (this.editingTarget.firstName === "") {
          this.invalidFields.push("firstName");
        }
        if (this.editingTarget.lastName === "") {
          this.invalidFields.push("lastName");
        }
        if (this.editingTarget.phoneNumber === "") {
          this.invalidFields.push("phoneNumber");
        }
        this.$store.dispatch("updateUser", this.editingTarget).then(() => {
          this.editingTarget = null;
        });
      }
    },
    cancel() {
      this.editingTarget = null;
    },
    getValidateStatus(field) {
      if (this.invalidFields.indexOf(field) === -1) {
        return "";
      } else {
        return "error";
      }
    },
  },
};
</script>
